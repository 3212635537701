.main_page {
    width: 100%;
    padding: 24px;
}
.errorPageSvg {
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}
.errorPageButton {
    cursor: pointer;

    & a {
        color: var(--t-secondary-c);
        text-decoration: none;
    }
}
