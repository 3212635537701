@import 'app/styles/config/mixin.scss';

.Sidebar {
    flex-shrink: 0;
    padding: 20px;
    width: 258px;
    border-right: 1px solid var(--s-secondary-c-2);

    @include before-768 {
        width: 80px;
    }

    @media screen and (max-width: 340px) {
        width: 70px;
    }

    @media screen and (max-width: 327px) {
        width: 60px;
    }
}

.logo {
    margin-bottom: 30px;
}

.logoLink {
    svg {
        width: 50px;
        height: auto;

        @media screen and (max-width: 475px) {
            width: 39px;
        }

        @media screen and (max-width: 340px) {
            width: 32px;
        }
    }
}
