@import 'app/styles/config/mixin.scss';

.Input {
    display: block;
}

.name {
    position: relative;
    display: block;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 400;
    color: var(--t-primery-c);
    background-color: transparent;
    transition: 0.5s;

    @include before_768 {
        font-size: 14px;
    }
}

.content {
    position: relative;
    // height: 100%;
}

.data {
    position: relative;
    padding: 19px;
    background-color: var(--s-secondary-c-1);
    border: 1px solid var(--s-secondary-c-5);
    border-radius: 12px;
    transition: 0.5s;

    @include before_768 {
        padding: 12px 16px;
    }
}

.input {
    width: 100%;
    padding: 0;
    font-size: 16px;
    color: var(--t-primery-c);
    background-color: transparent;
    border: none;
    outline: none;

    &::placeholder {
        font-size: 16px;
        line-height: 140%;
        color: var(--t-descriptive-c-1);
        letter-spacing: 0.04em;

        @include before-768 {
            font-size: 16px;
        }
    }
}

.note {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    color: var(--t-descriptive-c-1);
}

.errorMessage {
    margin-top: 6px;
    font-size: 14px;
    line-height: 140%;
    color: var(--t-accent-c);
}

.hidden {
    display: none;
}
