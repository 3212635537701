@import 'app/styles/config/mixin.scss';

.Button {
    --custom-width: auto;

    box-sizing: border-box;
    display: inline-flex;
    gap: 7px;
    align-items: center;
    justify-content: center;
    width: var(--custom-width);
    padding: 0;
    cursor: pointer;
    background: none;
    border: none;
}

.icon {
    display: inline-block;

    svg {
        display: block;
    }
}

.reverse {
    flex-direction: row-reverse;
}

.spinnerBox {
    display: flex;
    align-items: center;
}

.spinner {
    width: 20px;
    height: auto;
    stroke: var(--s-inverted-c);
    transition: 0.5s;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}


// Themes
.primary {
    color: var(--t-inverted-c);
    text-align: center;
    background-color: var(--s-primery-c);
    border: 1px solid var(--s-primery-c);
    border-radius: 30px;
    transition: 0.5s;

    .icon {
        svg {
            fill: var(--s-inverted-c);
            transition: 0.5s;
        }
    }

    &:hover {
        background-color: var(--s-primery-active-c);
        border-color: var(--s-primery-active-c);

        .spinner {
            stroke: var(--s-inverted-c);
        }
    }

    &[disabled] {
        background-color: var(--s-secondary-c-5);
        border-color: var(--s-secondary-c-5);
        pointer-events: none;
    }
}

.file_loader {
    background-color: var(--s-accent-c);
    border: 1px solid var(--s-accent-c);
    @extend .primary;

    &:hover {
        background-color: var(--s-accent-c);
        border-color: var(--s-accent-c);
    }
}

.accent {
    color: var(--t-inverted-c);
    text-align: center;
    background-color: var(--s-accent-c);
    border: 1px solid var(--s-accent-c);
    border-radius: 30px;
    transition: 0.5s;

    .icon {
        svg {
            fill: var(--s-inverted-c);
            transition: 0.5s;
        }
    }

    &:hover {
        opacity: 0.6;

        .spinner {
            stroke: var(--s-inverted-c);
        }
    }

    &[disabled] {
        background-color: var(--s-secondary-c-5);
        border-color: var(--s-secondary-c-5);
        pointer-events: none;
    }
}

.navigation {
    font-size: 14px;
    line-height: 18px;
    color: var(--t-accent-c);
    letter-spacing: .04em;

    @include before-1024 {
        font-size: 12px;
        line-height: 14px;
    }
}

.buttonModalText {
    color: var(--t-descriptive-c-1);

    &:hover {
        opacity: 0.7;
    }
}

.inverted {
    color: var(--t-accent-c);
    text-align: center;
    background-color: var(--s-inverted-c);
    border: 1px solid var(--s-primery-c);
    border-radius: 30px;
    transition: 0.5s;

    .icon {
        svg {
            fill: var(--s-primery-c);
            transition: 0.5s;
        }
    }

    &:hover {
        opacity: 0.6;

        .spinner {
            stroke: var(--s-primery-c);
        }
    }

    &[disabled] {
        background-color: var(--s-secondary-c-5);
        border-color: var(--s-secondary-c-5);
        pointer-events: none;
    }
}

.red-text {
    color: var(--t-accent-c);
    text-align: center;
    background-color: transparent;
    transition: 0.5s;

    .icon {
        svg {
            fill: var(--t-accent-c);
            transition: 0.5s;
        }
    }

    &:hover {
        opacity: 0.6;

        .spinner {
            stroke: var(---t-accent-c);
        }
    }

    &[disabled] {
        background-color: transparent;
        pointer-events: none;
    }
}

// Sizes
.xl {
    padding: 20px 50px;
    font-size: 16px;
}

.l {
    padding: 16px 32px;
    font-size: 14px;

    @media screen and (max-width: 941px) {
        padding: 16px 28px;
    }
}

.m {
    padding: 12px 24px;
    font-size: 14px;
}

.s {
    padding: 10px 20px;
    font-size: 14px;
}

.null {
    font-size: 14px;
}
