@import 'app/styles/config/mixin.scss';

.CourseWidget {
    position: relative;
    width: 100%;
    height: 100%;
}

.tabList {
    margin-bottom: 15px;
}

.item {
    display: block;
    padding: 15px;
    border-radius: 8px;
    background-color: var(--s-inverted-c);
    text-decoration: none;

    &:not(:last-child) {
        margin-bottom: 5px;
    }
}

.name {
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 500;
    color: var(--t-primery-c);
}

.percent {
    font-size: 10px;
    color: var(--t-descriptive-c-1);
}
