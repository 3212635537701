@import 'app/styles/config/mixin.scss';

.Title {
    font-size: 48px;
    font-weight: 700;
    color: var(--t-accent-c);
}

.Title {
    font-size: 48px;
    font-weight: 700;
    color: var(--t-accent-c);
    word-break: break-word;

    @media screen and (max-width: 798px) {
        font-size: 38px;
    }

    @media screen and (max-width: 475px) {
        font-size: 30px;
    }

    @media screen and (max-width: 369px) {
        font-size: 27px;
    }

    @media screen and (max-width: 360px) {
        font-size: 26px;
    }
}
