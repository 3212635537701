@font-face {
    font-family: 'Pragmatica';
    font-weight: 400;
    font-style: normal;
    src: url('shared/assets/fonts/Pragmatica-Book.woff2') format('woff2');
    font-display: swap;
}

@font-face {
    font-family: 'Pragmatica';
    font-weight: 500;
    font-style: normal;
    src: url('shared/assets/fonts/Pragmatica-Medium.woff2') format('woff2');
    font-display: swap;
}

@font-face {
    font-family: 'Pragmatica';
    font-weight: 700;
    font-style: normal;
    src: url('shared/assets/fonts/PragmaticaExtended-Bold.woff2') format('woff2');
    font-display: swap;
}
