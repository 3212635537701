.BlockLoader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;

    // display: none;
    width: 100%;
    height: 100%;
    background: transparent;
}

.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.box {
    width: 100px;
    height: 100px;
}

.icon {
    width: 100%;
    height: auto;
}
