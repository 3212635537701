.UsersPage {
    display: block;
}

.title {
    margin-bottom: 38px;
}

.modalTitle {
    margin-bottom: 30px;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: -0.75px;
    color: var(--t-primery-c);
}

.button {
    &:hover {
        opacity: 0.7;
    }

    &:first-child {
        margin-right: 8px;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 24px;

    @media screen and (max-width: 783px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.tableHeader {
    & {
        @media screen and (max-width: 1159px) {
            display: none;
        }
    }
}

.search {
    max-width: 300px;
}

.table {
    position: relative;
    width: 100%;
    height: 100%;

    td {
        max-width: 200px;
    }
}

.card {
    width: 100%;
    height: auto;

    border-bottom: 1px solid var(--s-secondary-c-2);

    display: flex;
    justify-content: space-between;

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        word-break: break-word;
        & > p {
            font-size: 14px;

            @media (min-width: 766) {
                font-size: 16px;
            }
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
}
