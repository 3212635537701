.tableRow tr:nth-child(even){
    background-color: red;
}

.tableContainer {
    overflow-x: auto;
    position: relative;
    scroll-behavior: smooth;
}

.arrowRight {
    display: none;
    position: fixed;

    right: 25px;
    top: 25%;
    width: 40px;
    height: 40px;

    &.show {
        display: none;    
        @media (max-width: 1650px) {
            display: block !important;
        }

    }

    &.isOnLessonStatisticPage {
        display: none !important;
    }


}

