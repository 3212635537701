.Footer {
    padding: 45px 0 30px;
    color: var(--s-inverted-c);
    background-color: var(--s-accent-c);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.logo {
    margin-bottom: 30px;
}

.container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 900px) {
        flex-direction: column;
    }
}
.contactBlock {
    text-align: left;

    @media screen and (max-width: 900px) {
        margin-bottom: 16px;
    }
}
.contactBlock p {
    margin:  0;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}
.contactBlock a {
    margin: 5px 0;
    color: var(--t-inverted-c);
    text-decoration: none;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
}
.policy {
    color: var(--t-inverted-c);
    text-decoration: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}

.socialIcons {
    font-size: 2rem;

    @media screen and (max-width: 990px) {
        display: flex;
        flex-direction: column;
    }

    @media screen and (max-width: 900px) {
        display: flex;
        flex-direction: row;
    }
}
