.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &__main {
        flex-grow: 1;
        padding-bottom: 130px;
    }
}

.rbc-month-view {
    border: 1px solid var(--Stroke-light-grey, #E8E8E8) !important;
    background: #FFF !important;
    border-radius: 14px !important;
}

.rbc-header {
    color: var(--light-grey-blue, #9C9EAC) !important;
    font-family: Pragmatica !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    padding: 10px !important;
}

.rbc-date-cell {
    text-align: left !important;
}

.rbc-button-link {
    color: #969696 !important;
    font-family: Pragmatica !important;
    font-size: 17.45px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    padding: 10px !important;
}

.rbc-row-segment {
    padding: 0 10px 4.5px !important;
}
