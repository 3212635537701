@import 'app/styles/config/mixin.scss';

.CourseCard {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background: var(--s-secondary-c-1);
    position: relative;

    @media screen and (max-width: 887px) {
        margin-bottom: 16px;
    }
}

.header {
    height: 150px;
}

.body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 25px 25px;
    text-decoration: none;
}

.courseName {
    overflow: hidden;
    color: var(--t-primery-c);
    text-overflow: ellipsis;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 10px;

    @media screen and (max-width: 737px) {
        word-break: break-word;
    }

    @media screen and (max-width: 1540px) {
        word-break: break-word;
    }
}

.description {
    overflow: hidden;
    color: var(--t-primery-c);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media screen and (max-width: 737px) {
        word-break: break-word;
    }

    @media screen and (max-width: 1540px) {
        word-break: break-word;
    }
}

.startDate {
    color: var(--t-primery-c);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 5px;
    &>span {
        color: var(--t-secondary-c);
    }
}

.theme {
    color: var(--t-primery-c);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 15px;
    &>span {
        color: var(--t-secondary-c);
    }
}

.tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 15px;
}

.tag {
    padding: 5px 12px;
    font-size: 14px;
    color: var(--t-primery-c);
    border-radius: 89px;
    border: 1px solid var(--s-secondary-c-5);
}

.statisticList {
    margin-top: auto;
}

.statistics {
    color: var(--t-descriptive-c-1);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.image {
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 16px 16px 0 0;
}
