.title {
    font-size: clamp(16px, 3vw, 48px) !important;
    margin-bottom: 25px;
}

.userCard {
    width: 100%;
    height: auto;

    margin-bottom: 20px;

    box-shadow: 0 0 6px -2px black;
    border-radius: 8px;

    padding: 15px;

    display: flex;
    justify-content: space-between;

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        & > p {
            font-size: 14px;

            @media (min-width: 766) {
                font-size: 16px;
            }
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
}

.formElement {
    border: none;
    background: var(--s-secondary-c-1);
    border-radius: 25px;
    padding: 5px;
    width: auto;
    margin-bottom: 15px;

    @media (max-width: 945px) {
        width: 100%;
    }
}