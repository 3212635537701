.Menu {
    width: 100%;
    padding: 0 30px;
    display: flex;
    align-items: center;
    //flex-direction: row;
    gap: 10px;
    //margin: 0 auto;

    li {
        font-size: 14px;
        //font-style: normal;
        //font-weight: 400;
        line-height: normal;
        font-family: var(--font-primery);
        color: var(--t-primery-c);
        cursor: pointer;
        //width: 24px;
        //height: 24px;
        //display: flex;
        //flex-direction: column;
        //justify-content: center;
        //a {
        //    color: var(--t-descriptive-c-1);
        //    text-decoration: none;
        //
        //    svg {
        //        fill: var(--font-primery);
        //    }
        //}
    }
}

.primary {
    max-width: 900px;
}
