.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    margin: 0 auto;

    &__item {
        &.selected {
            .pagination__link {
                color: var(--s-inverted-c);
                background-color: var(--s-primery-c);
                border-color: var(--s-primery-c);
            }
        }
        &.disabled {
            .pagination__link {
                color: var(--t-primery-c);
                background-color: var(--s-secondary-c-1);
                border-color: var(--s-secondary-c-1);
                cursor: auto;
            }
        }
    }

    &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 34px;
        height: 34px;
        padding: 0 10px;
        border: 1px solid var(--s-secondary-c-5);
        border-radius: 8px;
        cursor: pointer;


        &:hover {
            border-color: var(--s-primery-c);
        }
    }
}
