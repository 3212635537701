html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-weight: 400;
    font-family: var(--font-primery);
    font-size: 16px;
    line-height: normal;
    color: var(--t-primery-c);
    background-color: var(--bg-primery-c);
    scrollbar-gutter: stable;
}

a:hover {
    text-decoration: none;
}

.visually-hidden:not(:focus):not(:active),
input[type='checkbox'].visually-hidden,
input[type='radio'].visually-hidden {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;

    white-space: nowrap;

    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}

.rdg {
    font-size: var(--font-primery);
    color: var(--t-primery-c);
    border: none;
    block-size: auto;

    .rdg-header-row {
        background-color: transparent;

        .rdg-cell {
            border-bottom: 1px solid var(--s-secondary-c-2);
        }
    }

    .rdg-row {
        &:hover {
            background-color: var(--s-secondary-c-4);
        }

        &:nth-child(even) {
            background-color: var(--s-secondary-c-1);

            &:hover {
                background-color: var(--s-secondary-c-4);
            }
        }
    }

    .rdg-cell {
        display: flex;
        align-items: center;
        padding-inline: 15px;
        border: none;
    }
}

