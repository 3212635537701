.Wrapper {
    width: 100%;
    // max-width: 1500px;
    height: 100%;
    padding: 0 30px;

    @media screen and (max-width: 815px) {
        padding: 0 28px;
    }

    @media screen and (max-width: 382px) {
        padding: 0 25px;
    }

    @media screen and (max-width: 361px) {
        padding: 0 22px;
    }

    @media screen and (max-width: 360px) {
        padding: 0 20px;
    }

    @media screen and (max-width: 366px) {
        padding: 0 18px;
    }

    @media screen and (max-width: 352px) {
        padding: 0 14px;
    }

    @media screen and (max-width: 344px) {
        padding: 0 12px;
    }
}

.primary {
    max-width: 900px;
}
