.Breadcrumbs {
    width: 100%;
    padding: 0 30px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    height: 100%;
    align-items: center;
    //margin: 0 auto;
    li {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-family: var(--font-primery);
        color: var(--t-descriptive-c-1);
        a {
            color: var(--t-descriptive-c-1);
            text-decoration: none;
        }
    }

    @media screen and (max-width: 608px) {
        display: none;
    }
}

.primary {
    max-width: 900px;
}
