.FileLoader {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23214FE2' stroke-width='2' stroke-dasharray='24%2c 14' stroke-dashoffset='15' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 12px;
    padding: 25px;
    width: 100%;
}

.List {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.Tip {
    opacity: .4;
    font-size: 14px;
    margin-top: 10px;
}

.Title {
    color: var(--color-blue);
}

.Button {
    margin-top: 10px;
}

.Item {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 12px;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray);
    border-radius: 12px;
    margin-top: 15px;
}

.ItemLeft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-grow: 1;
}
.ItemRight{display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex-grow: 1;
}
.ItemPreview {
}

.ItemName {
    margin-left: 20px;
    color: var(--color-gray);

    @media screen and (max-width: 564px) {
        display: none;
    }
}
.ItemSize {
    color: var(--color-gray);
}
.ItemDelete {
    position: relative;
    top: -18px;
    left: 18px;
    z-index: 1;
}

.ListTitle {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.Error {
    color: var(--color-red)
}
