.wrapper {
    position: relative;
    // @media (max-width: 766px) {
    //     overflow-x: hidden;
    // }
}
.button {
    position: absolute;
    top: 0;
    padding: 10px;
    right: 0;
}

.form {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;

    @media (max-width: 945px) {
        flex-wrap: nowrap;
        flex-direction: column;
    }
}

.element {
    // border: none;
    // background: var(--s-secondary-c-1);
    // border-radius: 25px;
    // padding: 5px 5px 5px 10px;
    width: auto;
    min-width: 210px;

    @media (max-width: 945px) {
        min-width: none;
        width: 100%;
    }
}

.search {
    border-color: hsl(0, 0%, 80%);
    border-width: 1px;
    height: 38px;
    border-style: solid;
    border-radius: 4px;
    padding: 0 8px;
    min-width: 210px;

    @media (max-width: 945px) {
        min-width: none;
        width: 100%;
    }
}

.view {
    text-decoration: underline;
    cursor: pointer;
}

.modal {
    max-width: 1240px;
    transition: none;
    padding: 28px 32px 0px 32px;

    @media screen and (max-width: 1159px) {
        max-width: 666px;
    }
}
