body.light {
    //Text colors
    --t-primery-c: var(--color-black);
    --t-accent-c: var(--color-red);
    --t-accent-c-2: var(--color-green);
    --t-secondary-c: var(--color-blue);
    --t-inverted-c: var(--color-white);
    --t-descriptive-c-1: var(--color-gray);
    --t-third-c: var(--color-black2);
    --t-descriptive-c-2: var(--color-gray-2);

    //Surface colors
    --s-primery-c: var(--color-red);
    --s-primery-active-c: var(--color-red-2);
    --s-accent-c: var(--color-blue);
    --s-accent-c-2: var(--color-green);
    --s-inverted-c: var(--color-white);
    --s-secondary-c-1: var(--color-gray-2);
    --s-secondary-c-2: var(--color-gray-3);
    --s-secondary-c-3: var(--color-red-rgba10);
    --s-secondary-c-4: var(--color-blue-rgba10);
    --s-secondary-c-5: var(--color-gray);
    --s-secondary-c-6: var(--color-black);
    --s-secondary-c-7: var(--color-green-2);
    --s-secondary-c-8: var(--color-scroll);

    //Background colors
    --bg-primery-c: var(--color-white);
}
