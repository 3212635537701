:root {
    --font-primery: 'Pragmatica', 'Arial', sans-serif;
    --scroll-width: calc(100vw - 100%);
    --color-black: #18203B;
    --color-black2: #000;
    --color-black3: #1F2948;
    --color-white: #fff;
    --color-green: #1DB81D;
    --color-green-2: #EBFCEB;
    --color-gray: #9C9EAC;
    --color-gray-2: #F7F9FB;
    --color-gray-3: #E8E8E8;
    --color-blue: rgb(33, 79, 226);
    --color-blue-rgba10: rgba(33, 79, 226, 0.10);
    --color-red: #DE3A34;
    --color-red-rgba10: rgba(222, 58, 52, 0.10);
    --color-red-2: #FF0A01;
    --color-scroll: #e3e9f8;
}
