@import 'app/styles/config/mixin.scss';
//.Select{
//
//}
.SelectComponent {
    position: relative;
    padding: 17px 9px;
    background-color: var(--s-secondary-c-1);
    border: 1px solid var(--s-secondary-c-5);
    border-radius: 12px;
    transition: 0.5s;

    @include before_768 {
        padding: 12px 16px;
    }
}

.errorMessage {
    position: absolute;
    bottom: -25px;
    font-size: 14px;
    color: var(--t-accent-c);
}
//.Select > div {
//    //height: 60px;
//    border-radius: 12px;
//    border-color: var(--s-secondary-c-5);
//}

//.Select > div > div:last-child > span {
//    display: none;
//}
//
//.Select > div > div:last-child > div > svg {
//    color: #000;
//}
