@import 'app/styles/config/mixin.scss';

.FeedbackForm {
    display: block;
}

.title {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 700;
    color: var(--t-primery-c);
}

.list {
    margin-bottom: 30px;
}

.row {
    font-size: 14px;
    font-weight: 400;
    color: var(--t-descriptive-c-1);

    &:not(:last-child) {
        margin-bottom: 5px;
    }
}

.field {
    margin-bottom: 30px;
}
