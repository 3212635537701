@import 'app/styles/config/mixin.scss';

.CourseList {
    display: grid;
    grid-template-columns: repeat(3, calc((100% - 50px) / 3));
    gap: 25px;
    @include before-1024 {
        grid-template-columns: repeat(2, calc((100% - 25px) / 2));
    }
    @include before-480 {
        grid-template-columns: repeat(1, 1fr);
    }

    @media screen and (max-width: 1540px) {
        gap: 15px;
        grid-template-columns: repeat(2, calc((100% - 15px) / 2));
    }
}

@media screen and (max-width: 1167px) {
    .CourseList {
        gap: 24px;
        grid-template-columns: repeat(2, calc((100% - 24px) / 2));
    }
}

@media screen and (max-width: 1165px) {
    .CourseList {
        gap: 23px;
        grid-template-columns: repeat(2, calc((100% - 23px) / 2));
    }
}

@media screen and (max-width: 1163px) {
    .CourseList {
        gap: 22px;
        grid-template-columns: repeat(2, calc((100% - 22px) / 2));
    }
}

@media screen and (max-width: 1161px) {
    .CourseList {
        gap: 21px;
        grid-template-columns: repeat(2, calc((100% - 21px) / 2));
    }
}

@media screen and (max-width: 894px) {
    .CourseList {
        gap: 20px;
        grid-template-columns: repeat(2, calc((100% - 20px) / 2));
    }
}

@media screen and (max-width: 893px) {
    .CourseList {
        gap: 19px;
        grid-template-columns: repeat(2, calc((100% - 19px) / 2));
    }
}

@media screen and (max-width: 892px) {
    .CourseList {
        gap: 18px;
        grid-template-columns: repeat(2, calc((100% - 18px) / 2));
    }
}

@media screen and (max-width: 891px) {
    .CourseList {
        gap: 17px;
        grid-template-columns: repeat(2, calc((100% - 17px) / 2));
    }
}

@media screen and (max-width: 890px) {
    .CourseList {
        gap: 16px;
        grid-template-columns: repeat(2, calc((100% - 16px) / 2));
    }
}

@media screen and (max-width: 889px) {
    .CourseList {
        gap: 15px;
        grid-template-columns: repeat(2, calc((100% - 15px) / 2));
    }
}

@media screen and (max-width: 888px) {
    .CourseList {
        gap: 14px;
        grid-template-columns: repeat(2, calc((100% - 14px) / 2));
    }
}

@media screen and (max-width: 887px) {
    .CourseList {
        gap: 0;
        grid-template-columns: none;
    }
}
