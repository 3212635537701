@import 'app/styles/config/mixin.scss';

.AppLink {
    --custom-width: auto;

    display: inline-flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    width: var(--custom-width);
    color: var(--t-primery-c);
    text-decoration: none;
}

.icon {
    svg {
        display: block;
    }
}

.reverse {
    flex-direction: row-reverse;
}

// Themes
.primary {
    color: var(--t-inverted-c);
    text-align: center;
    background-color: var(--s-primery-c);
    border: 1px solid var(--s-primery-c);
    border-radius: 30px;
    transition: 0.5s;

    .icon {
        svg {
            fill: var(--t-primery-c);
            transition: 0.5s;
        }
    }

    &:hover {
        background-color: var(--s-primery-active-c);
        border-color: var(--s-primery-active-c);

        .spinner {
            stroke: var(--s-inverted-c);
        }
    }

    &[disabled] {
        background-color: var(--s-secondary-c-5);
        border-color: var(--s-secondary-c-5);
        pointer-events: none;
    }
}

.accent {
    color: var(--t-inverted-c);
    text-align: center;
    background-color: var(--s-accent-c);
    border: 1px solid var(--s-accent-c);
    border-radius: 30px;
    transition: 0.5s;

    .icon {
        svg {
            fill: var(--t-primery-c);
            transition: 0.5s;
        }
    }

    &:hover {
        opacity: 0.6;

        .spinner {
            stroke: var(--s-inverted-c);
        }
    }

    &[disabled] {
        background-color: var(--s-secondary-c-5);
        border-color: var(--s-secondary-c-5);
        pointer-events: none;
    }
}

.buttonModalText {
    color: var(--t-descriptive-c-1);

    &:hover {
        opacity: 0.7;
    }
}

.inverted {
    color: var(--t-accent-c);
    text-align: center;
    background-color: var(--s-inverted-c);
    border: 1px solid var(--s-primery-c);
    border-radius: 30px;
    transition: 0.5s;

    .icon {
        svg {
            fill: var(--s-primery-c);
            transition: 0.5s;
        }
    }

    &:hover {
        opacity: 0.6;

        .spinner {
            stroke: var(--s-primery-c);
        }
    }

    &[disabled] {
        background-color: var(--s-secondary-c-5);
        border-color: var(--s-secondary-c-5);
        pointer-events: none;
    }
}

.navigation {
    font-size: 14px;
    color: #9da1af;
    letter-spacing: .04em;

    @include before-1024 {
        font-size: 12px;
        line-height: 14px;
    }

    .icon {
        svg {
            fill: var(--t-primery-c);
        }
    }
}

// Sizes
.xl {
    padding: 20px 50px;
    font-size: 16px;

    @media screen and (max-width: 472px) {
        padding: 14px 38px;
        font-size: 14px;
    }

    @media screen and (max-width: 321px) {
        padding: 14px 28px;
    }

    @media screen and (max-width: 306px) {
        padding: 14px 26px;
    }

    @media screen and (max-width: 302px) {
        padding: 14px 22px;
    }

    @media screen and (max-width: 294px) {
        padding: 14px 18px;
    }
}

.l {
    padding: 16px 32px;
    font-size: 14px;
}

.m {
    padding: 12px 24px;
    font-size: 14px;
}

.s {
    padding: 10px 20px;
    font-size: 14px;
}

.xs {
    padding: 5px 12px;
    font-size: 14px;
}
