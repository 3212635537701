.wrapper {
    overflow-x: auto;
}

.title {
    font-size: clamp(16px, 3vw, 48px) !important;
    margin-bottom: 25px;
}

.userCard {
    width: 100%;
    height: auto;
    max-height: none;

    margin-bottom: 20px;

    box-shadow: 0 0 6px -2px black;
    border-radius: 8px;

    padding: 15px;

    display: flex;
    justify-content: space-between;
    gap: 10px;

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        & > p {
            font-size: 13px;

            @media (min-width: 766) {
                font-size: 16px;
            }
        }

        &__name {
            display: -webkit-box;
            -webkit-line-clamp: 3; /* Замените 3 на нужное количество строк */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        & > *:first-child {
            margin-bottom: 10px;
        }
    }
}

.formElement {
    border: none;
    background: var(--s-secondary-c-1);
    border-radius: 25px;
    padding: 5px;
    width: auto;
    margin-bottom: 15px;

    @media (max-width: 945px) {
        width: 100%;
    }
}