.table {
    width: 100%;
    margin-bottom: 30px;
    border-collapse: collapse;

    th {
        padding: 13px 15px;
        font-family: var(--font-primery);
        font-size: 14px;
        font-weight: 700;
        color: var(--t-primery-c);
        text-align: left;
        background: var(--s-inverted-c);
        border-bottom: 1px solid var(--s-secondary-c-2);
    }

    td {
        padding: 22px 15px;
        font-family: var(--font-primery);
        font-size: 16px;
        color: var(--t-primery-c);
        text-align: left;
    }

    tbody:nth-child(odd) tr {
        background: var(--s-inverted-c);

        &.table__clickable {
            cursor: pointer;

            &:hover {
                background-color: var(--s-secondary-c-4);
            }
        }
    }

    tbody:nth-child(even) tr {
        background: var(--s-secondary-c-1);

        &.table__clickable {
            cursor: pointer;

            &:hover {
                background-color: var(--s-secondary-c-4);
            }
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
