@import 'app/styles/config/mixin.scss';

.Layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
}

.container {
    display: flex;
    flex-grow: 1;
}

.content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 100px;
    overflow-x: hidden;

    //@media screen and (max-width: 419px) {
    //    margin-bottom: 16px;
    //    width: 80%;
    //}
}

.main {
    flex-grow: 1;
}
