@import 'app/styles/config/mixin.scss';

.Header {
    padding: 15px 0;
    margin-bottom: 30px;
    border-bottom: 1px solid var(--s-secondary-c-2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
