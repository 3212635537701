@import 'app/styles/config/mixin.scss';

.Navigation {
    position: relative;
}
.list {
    display: flex;
    flex-direction: column;
}

.blockItem {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: var(--font-primery);
    color: var(--t-primery-c);
}

.item {
    margin-bottom: 20px;
    :hover {
        color: var(--t-accent-c);
        svg {
            fill:  var(--t-accent-c);
        }
    }
    svg {
        fill: var(--font-primery);
    }
}

@media screen and (max-width: 767px) {
    .item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 22px;
    }
}

.textDesktop {
    @include before-768 {
        display: none;
    }
}
