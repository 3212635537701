.CardEditButton {
    display: flex;
    flex-direction: row;
    gap: 2px;
    outline: none;
    background: transparent;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    padding: 10px;

    &:hover {
        opacity: 0.7;
    }
}

.contextMenu {
    border-radius: 16px;
    border: 1px solid var(--s-secondary-c-5);
    background: var(--s-inverted-c);
    padding: 30px;
    position: absolute;
    top: 30px;
    right: 10px;
    min-width: 280px;
    max-width: calc(100% - 10px);
    z-index: 10;

    @media screen and (max-width: 399px) {
        & {
            padding: 16px;
            right: 0;
            min-width: 188px;
        }
    }

    &Element {
        color: var(--t-third-c);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        cursor: pointer;

        &:hover {
            color: var(--t-accent-c);
        }

        &:not(:last-of-type) {
            margin-bottom: 15px;
        }
    }
}
