@import 'app/styles/config/mixin.scss';

.Tab {
    display: block;
}

.list {
    display: flex;
}

@media screen and (max-width: 820px) {
    .list {
        flex-direction: column;
    }
}

.item {
    display: none;

    &.active {
        display: block;
    }
}

.name {
    flex-grow: 1;
    padding: 10px;
    font-size: 16px;
    color: var(--t-primery-c);
    cursor: pointer;
    background: none;
    border: none;
    font-weight: 400;
    border-bottom: 1px solid var(--s-secondary-c-2);

    &.active {
        color: var(--t-accent-c);
        border-bottom: 1px solid var(--s-primery-c);
    }
}

.disabled {
    color: var(--color-gray);
    cursor: unset;
}