@import 'app/styles/config/mixin.scss';

.ListOfReferences {
    display: block;
}

.header {
    & {
        @media screen and (max-width: 931px) {
            display: none;
        }
    }
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 30px;
    & th {
        border-bottom: 1px solid var(--s-secondary-c-2);
        text-align: left;
    }
    & tbody:nth-of-type(even) {
        background: var(--s-secondary-c-1);
    }
    & th, td {
        padding: 22px 15px;
    }
    & td {
        cursor: pointer;
        &:last-child {
            width: 50px;
        }
    }
}
.table__clickable td {
    color: var(--t-primery-c);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}
.close td {
    color: var(--t-descriptive-c-1);
}
.badge {
    border-radius: 30px;
    background: var(--s-secondary-c-2);
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    &.new {
        background: var(--s-primery-c);
        color: var(--t-inverted-c)
    }
}

.userCard {
    width: 100%;
    height: auto;

    margin-bottom: 20px;

    box-shadow: 0 0 6px -2px black;
    border-radius: 8px;

    padding: 15px;

    display: flex;
    justify-content: space-between;

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        & > p {
            font-size: 14px;

            @media (min-width: 766) {
                font-size: 16px;
            }
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        //justify-content: space-evenly;
    }
}
