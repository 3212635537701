.title {
    font-size: clamp(16px, 3vw, 48px) !important;
    margin-bottom: 25px;
}

.userCard {
    width: 100%;
    height: auto;
    
    margin-bottom: 20px;

    box-shadow: 0 0 6px -2px black;
    border-radius: 8px;

    padding: 15px;

    &__header {
        display: flex;
        justify-content: space-between;

        margin-bottom: 15px;

        &__number {
            margin-right: 15px;
        }

        &__button {
            padding: 0;
        }
    }

    &__mainContent {
        display: flex;
        margin-bottom: 15px;

        &__stats {
            display: flex;
            flex-direction: column;
            align-items: center;
            
            margin-right: 12px;
        }

        &__diagramWrapper {
            position: relative;
            width: 70px;
            height: 70px;

            margin-bottom: 25px;

            &__title {
                font-size: 14px;
            }

            &__percentage {
                position: absolute;
                left: 33px;
                top: 34px;
                font-size: 14px;
            };
        }



        &__info {
            & > p {
                font-size: 14px;

                @media (min-width: 766) {
                    font-size: 16px;
                }
            }
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;

        &__first {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            & > p {
                font-size: 14px;
    
                @media (min-width: 766) {
                    font-size: 16px;
                }
            }
        }

        &__second {
            display: flex;
            justify-content: space-between;
            & > p {
                font-size: 14px;
    
                @media (min-width: 766) {
                    font-size: 16px;
                }
            }
        }
    }
}

.eyeButton {
    // position: absolute;
}

.tr {
    // position: relative;
}

.statisticButton {
    // position: absolute;
    // top: 26px;
    // right: -5px;
}